import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageProps, graphql } from 'gatsby';
import compact from 'lodash/compact';
import get from 'lodash/get';
import classnames from 'classnames';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import HeroPost from '../../components/HeroPost';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import Breadcrumb from '../../components/Breadcrumb';
import Divider from '../../components/Divider';
import TileCard from '../../components/TileCard';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import { ModalRawHTML } from '../../components/RawHTML/ModalRawHTML';
import SEO from '../../components/SEO/SEO';
import { Scalars, SingleExercisePostQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './exercise-post.module.scss';

type PageContextType = GlobalPageContext & {
  documentId: Scalars['ID'];
  relatedIds: Scalars['ID'][];
};

const ExercisePage: React.FC<PageProps<SingleExercisePostQuery, PageContextType>> = ({ data, pageContext }) => {
  const { relatedExercises, exercise } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const intl = useIntl();

  // TODO: better error state
  if (!exercise || !websiteLocale) return <p>Page not found</p>;
  const showFooterCta = exercise?.pageSettings?.showFooterCta ?? false;
  const footerCta = exercise?.pageSettings?.customFooterCta?.documentId
    ? exercise?.pageSettings?.customFooterCta
    : websiteLocale.footerCta;
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={exercise.seo?.metaTitle ?? exercise.pageSettings?.title ?? exercise.title ?? ''}
        description={exercise.seo?.metaDescription ?? exercise.pageSettings?.metaDescription ?? exercise.excerpt}
        image={exercise.seo?.metaImage?.url ?? exercise.image?.url}
        avoidIndexing={exercise.pageSettings?.avoidIndexing ?? false}
        canonicalURL={exercise.seo?.canonicalURL}
        keywords={exercise.seo?.keywords}
        metaSocial={exercise.seo?.metaSocial}
        structuredData={exercise.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className="container">
        <Breadcrumb pageContext={pageContext} lastCrumb={exercise.title ?? ''}></Breadcrumb>
      </div>

      <HeroPost
        variant="exercise"
        title={exercise.title ?? ''}
        categories={compact(exercise?.categories)}
        date={exercise.originalDate ?? exercise.publishedAt}
        imageSrc={exercise.image?.url}
        showDate={exercise.showDate}
      />

      <RichTextField className={styles.container} content={exercise.body} />
      <ModalRawHTML className="container" html={exercise.htmlCode ?? ''} />

      <div
        className={classnames(
          'container page-components',
          styles['pageComponents'],
          websiteLocale?.hasRightToLeftLanguage ? styles['courseColumnListRtl'] : '',
        )}
      >
        {exercise?.components?.map((component, idx) =>
          component ? (
            <ComponentRenderer
              component={component}
              key={`exercise-component-${component.__typename}-${idx}`}
              hasRTL={websiteLocale?.hasRightToLeftLanguage}
            />
          ) : null,
        )}
      </div>

      {compact(relatedExercises).length ? (
        <div className="container">
          <Divider paddingTop="100px" marginBottom="20px">
            <FormattedMessage id="exercisesDividerText" defaultMessage="More Exercises" />
          </Divider>
          <div className={styles.gridWrapper}>
            {compact(relatedExercises).map(
              ({ documentId, title, slug, image, categories, excerpt, publishedAt, showDate, originalDate }) => (
                <TileCard
                  linkText={intl.formatMessage({ id: 'exercisesCardText', defaultMessage: 'View Exercise' })}
                  slugBase={pageContext.pathPrefixes.exercises}
                  key={`exercise-${documentId}`}
                  variant="exercise"
                  title={title ?? 'N/A'}
                  slug={slug ?? ''}
                  image={image?.url}
                  categories={compact(categories)}
                  short={excerpt ?? ''}
                  date={originalDate ?? publishedAt}
                  showDate={showDate}
                />
              ),
            )}
          </div>
        </div>
      ) : null}
      {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
fragment ExercisePost on Strapi_Exercise {
  ...ExerciseBase
  body
  htmlCode: html_code
  excerpt
  pageSettings: page_settings {
    ...PageSettings
  }
  components {
    ...PageComponents
  }
  seo {
    ...SEOComponent
  }
}

query SingleExercisePost($documentId: ID!, $relatedIds: [ID!], $locale: Strapi_I18NLocaleCode) {
  strapi {
    relatedExercises: exercises(filters: {documentId: {in: $relatedIds}}, locale: $locale) {
      data {
        documentId
        attributes {
          ...ExerciseCard
        }
      }
    }
    exercise(documentId: $documentId,locale: $locale) {
      data {
        documentId
        attributes {
          ...ExercisePost
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(ExercisePage));
